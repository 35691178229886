(function () {
  'use strict';

  class ModuleSetRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    moduleSets() {
      return this.$resource(`${this.env.api}/module_sets`);
    }
    moduleSet() {
      return this.$resource(`${this.env.api}/module_sets/:id`, {id: '@id'}, {update: {method: 'PUT'}});
    }
    moduleDocuments() {
      return this.$resource(`${this.env.api}/module_sets/:id/module_documents`, {id: '@id'});
    }
    moduleCategories() {
      return this.$resource(`${this.env.api}/module_sets/:id/module_categories`, {id: '@id'});
    }
    moduleSetDocuments() {
      return this.$resource(`${this.env.api}/module_sets/:id/module_documents/by_categories`, {id: '@id'}, {update: {method: 'PUT'}});
    }
    moduleSetClone() {
      return this.$resource(`${this.env.api}/module_sets/:id/clone`, {id: '@module_set.id'}, {update: {method: 'PUT'}});
    }
    unlock() {
      return this.$resource(`${this.env.api}/module-sets/:id/module/:module_id`, {id: '@id', module_id: '@module_id'}, {update: {method: 'PUT'}});
    }
  }

  /**
   * @ngdoc service
   * @name components.service:ModuleSetRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('ModuleSetRest', ModuleSetRest);
}());
